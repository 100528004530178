import "./impantologsButton.css"
import toothscanImg from "../../img/implantologs.png"

export default function ImpantologsButton() {
    return (
      <div tabIndex="0" className="btnRight impantologs_button_container doctor_swiper_button small_mover_button_color_disabled">
        <img src={toothscanImg}/>
        <p>Имплантологи</p>
      </div>
    );
  }