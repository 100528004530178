import "./backToSearchButton.css"
import { Link } from "react-router-dom"
import backToSearchArrowImg from "../../img/backToSearchArrow.png";
export default function BackToSearchButton() {
    const WindowBack=()=>{
        window.history.back()
    }
    return(
        <div tabIndex="0" className="btnLeft back_to_search_container" onClick={WindowBack}>
            <img src={backToSearchArrowImg}/>
            <p>Вернуться к поиску</p>
            
        </div>
    )
}