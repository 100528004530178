import "./paradontologsButton.css"
import toothchairImg from "../../img/paradontologs.png"

export default function ParadontologsButton() {
    return (
      <div tabIndex="0" className="btnRight paradontologs_button_container doctor_swiper_button small_mover_button_color_disabled">
        <img src={toothchairImg}/>
        <p>Парадонтологи</p>
      </div>
    );
  }