import "./servicesDoctorsPage.css"
import ImpantologsButton from "../../components/impantologsButton/impantologsButton"
import TerapephtsButton from "../../components/terapephtsButton/terapephtsButton"
import OrthodontistsButton from "../../components/orthodontistsButton/orthodontistsButton"
import GnatologsButton from "../../components/gnatologsButton/gnatologsButton"
import SurgeonsButton from "../../components/surgeonsButton/surgeonsButton"
import OrthopedistsButton from "../../components/orthopedistsButton/orthopedistsButton"
import GigienistsButton from "../../components/gigienistsButton/gigienistsButton"
import ParadontologsButton from "../../components/paradontologsButton/paradontologsButton"
import PediatricianButton from "../../components/pediatricianButton/pediatricianButton"
import YourQuestionsHeader from "../../components/yourQuestionsHeader/yourQuests"
import backToStartImg from "../../img/backToStartArrow.png"
import { Link } from "react-router-dom"
import {useState, useEffect} from "react";
import AnimatedPage from "../AnimatedPage"
export default function ServicesDoctorsPage(){
    const [backLink, setBackLink]=useState("")
    const FunBackLink=()=>{
        setBackLink("/servicesDoctorsPage")
        console.log("ky")
    }
    return(
        <AnimatedPage>
        <div className="services_doctos_page_container">
            <YourQuestionsHeader backLink={backLink}/>
            <div className="services_doctos_page_row services_doctos_page_first_row">
                <Link to="/docslist" onClick={FunBackLink}><GnatologsButton/></Link>
                <Link to="/docslist" onClick={FunBackLink}><SurgeonsButton/></Link>
                <Link to="/docslist" onClick={FunBackLink}><ImpantologsButton/></Link>
            </div>
            <div className="services_doctos_page_row">
                <Link to="/docslist" onClick={FunBackLink}><OrthopedistsButton/></Link>
                <Link to="/docslist" onClick={FunBackLink}><TerapephtsButton/></Link>
                <Link to="/docslist" onClick={FunBackLink}><OrthodontistsButton/></Link>
            </div>
            <div className="services_doctos_page_row">
                <Link to="/docslist" onClick={FunBackLink}><PediatricianButton/></Link>
                <Link to="/docslist" onClick={FunBackLink}><GigienistsButton/></Link>
                <Link to="/docslist" onClick={FunBackLink}><ParadontologsButton/></Link>
            </div>
        </div>
        </AnimatedPage>
    )
}