export const DelayAnimation = 500;
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get("key");
let keys;
if (myParam) {
  console.log("sss");
  keys = myParam;
  localStorage.keySt = keys;
} else {
  console.log("ssss");
  keys = localStorage.getItem("keySt");
}

export const Id = keys;
export const domain = "https://comeback-team.ru";
export const doctors = [
  {
    id: "0",
    name: "Князева Анастасия Николаевна ",
    rolledToName: "Вы записаны на прием к Скаковскому Андрею Григорьевичу!",
    spec: "Главный врач",
    moreSpec: "Главный врач",
    pac: "1500+",
    stage: "8 лет",
    age: "29 лет",
    info: "Закончила Россиский Университет Дружбы Народов. После закончила ординатуру по специальности ёстоматология ортопедическаяё в МОНИКИ...",
    moreInfo:
      "Закончила Россиский Университет Дружбы Народов. После закончила ординатуру по специальности 'стоматология ортопедическая' в МОНИКИ им. Владимирского. Ведет прием в области терапии и ортопедии. Эстетическая стоматология. Восстановление зубов современными ортопедическими конструкциями. Лечение пульпитов и периодонтитов. Проведение комплексных консультаций и составление плана лечения.",
  },
  {
    id: "1",
    name: "Вайткевичус Олег Петрович",
    rolledToName: "Вы записаны на прием к Вайткевичус Олегу Петровичу!",
    spec: "Имплантация",
    moreSpec:
      "Специализация 3D-мезонити, зубосохраняющие операции удаление зубов любой сложности, имплантация, инъекционная косметология, инъекционная мезотерапия лица, контурная пластика.",
    pac: "8000+",
    stage: "30 лет",
    age: "56 лет",
    info: "Проводит удаление зубов различной сложности, операции в амбулаторных условиях, вскрытие абсцессов, иссечение зубного капюшона и т.д.",
    moreInfo:
      "Проводит удаление зубов различной сложности, операции в амбулаторных условиях, вскрытие абсцессов, иссечение зубного капюшона, удаление новообразований полости рта, вправление вывихов, челюстных суставов, лечение заболеваний челюстных суставов.зубосохраняющие операции,имплантация. На счету врача более 2000 установок имплантов. Приживаемость имплантатов у Олега Петровича 99% .",
  },
  {
    id: "2",
    name: "Корецкая Ирина Александровна",
    rolledToName: "Вы записаны на прием к Корецкой Ирине Александровне!",
    spec: "Стоматолог - ортодонт",
    moreSpec: "Врач стоматолого - ортодонт",
    pac: "3500+",
    stage: "20 лет",
    age: "41 год",
    info: "В своей работе использует методику обследования челюстно-лицевой области, включая: обследование мягких тканей лица, височно-нижнечелюстного сустава и т.д.",

    moreInfo:
      "Корецкая Ирина Александровна. Миофункциональный ортодонт. окончила в России Кемеровскую Государственную Медицинскую Академию г. Кемерово в 2002 году по специальности стоматология. В 2003 году прошла интернатуру на базе МЛПУ стоматологическая поликлиника №6 г.Новокузнецка по специальности стоматология. В 2007 на базе Новосибирского Государственного Медицинского Университета прошла профессиональную переподготовку по ортодонтии. С 2007 года работаю врачом ортодонтом. Практика в сменном и постоянном прикусе. В своей работе использует методику обследования челюстно-лицевой области, включая: обследование мягких тканей лица, височно-нижнечелюстного сустава, данные расчёта 3д рентгенологических исследований . Участвует в составлении комплексного плана лечения. Подготовка к ортопедическому лечению. Проводит раннее ортодонтическое лечение в миофункциональной концепции, используя возможности роста . Механическое расширение ВЧ по методике М.Rossa. Также в своей практике использует несъемную ортодонтическую систему 2*4, Damon, H4 и пр.",
  },
  {
    id: "3",
    name: "Заикин Павел Фёдорович",
    rolledToName: "Вы записаны на прием к Заикину Павлу Фёдоровичу!",
    spec: "Ведущий ортопед",
    moreSpec:
      "Специализация: врач стоматолог общей практики, врач ведущий стоматолог-ортопед, врач стоматолог-хирург",
    pac: "3200+",
    stage: "15 лет",
    age: "37 лет",
    info: "Занимается: атравматическими методиками лечения в стоматологической практике. Ортопедическая практика, эстетические решения в ортопедической стоматологии.",

    moreInfo:
      "Занимается: атравматическими методиками лечения в стоматологической практике. Ортопедическая практика, эстетические решения в ортопедической стоматологии, ортопедические конструкции на имплантантах. Хирургическая практика, удаление зубов, имплантация.",
  },
  {
    id: "4",
    name: "Харченко Мария Евгеньевна",
    rolledToName: "Вы записаны на прием к Харченко Марие Евгеньевне!",
    spec: "Стоматолог-терапевт",
    moreSpec: "Специализация: врач стоматолог-терапевт",
    pac: "2500+",
    stage: "14 лет",
    age: "36 лет",
    info: "Лечение кариеса, пульпитов, периодонтитов . Восстанавливает коронкиовые части на всех групп зубов, проводит рестоврацию фотокомпозитом зубов в зоне улыбки.",

    moreInfo:
      "Мария Евгеньевна более 14 лет успешно занимается лечением кариеса , пульпитов ,периодонтитов . Восстанавливает коронковые части на всех групп зубов, проводит реставрацию фотокомпозитом зубов в зоне улыбки. У нее можно пройти профессиональную гигиену полости рта и отбеливание.",
  },
  {
    id: "5",
    name: "Смирнов Виталий Александрович",
    rolledToName: "Вы записаны на прием к Смирнову Виталию Александровичу",
    spec: "Стоматолог-ортопед",
    moreSpec:
      "Специализация: врач стоматолог общей практики, врач стоматолог-ортопед, врач стоматолог-терапевт",
    pac: "1500+",
    stage: "8 лет",
    age: "-",
    info: "Ортопедическая и терапевтическая стоматологии: успешно проводит мероприятия по устранению заболеваний полости рта и эстетическому восстановлению зубов и т.д.",

    moreInfo:
      "Ведет врачебную практику в области ортопедической и терапевтической стоматологии, успешно проводя мероприятия по устранению заболеваний полости рта и эстетическому восстановлению зубов.",
  },
  {
    id: "6",
    name: "Албогачиева Азалия Алиевна",
    rolledToName: "Вы записаны на прием к Албогачиевой Азалии Алиевне!",
    spec: "Стоматолого - ортодонт",
    moreSpec: "Врач стоматолого - ортодонт",
    pac: "500+",
    stage: "3 лет",
    age: "26 лет",
    info: "Профилактика, диагностические мероприятия и лечение патологий зубочелюстной системы. Установка брекет-систем, восстановление утраченных фрагментов зубов.",

    moreInfo:
      "На счету врача более 100 новых улыбок. Профилактика, диагностические мероприятия и лечение патологий зубочелюстной системы. Установка брекет-систем, восстановление утраченных фрагментов зубов.",
  },
];
