import "./servicesSurgeonsPage.css";
import YourQuestionsHeader from "../../components/yourQuestionsHeader/yourQuests";
import ServicesSection from "../../components/servicesSection/servicesSection";
import AnimatedPage from "../AnimatedPage";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import img2 from "./hotServicesImg1.png";
import img4 from "./hotServicesImg2.png";
import img9 from "./hotServicesImg3.png";
import { domain } from "../../const";
import { Id } from "../../const";
export default function ServicesSurgeonsPage() {
  const [services, setServices] = useState([]);
  useEffect(() => {
    let formData = new FormData();
    formData.append("ID", Id);
    fetch(domain + "/getServices.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setServices(data);
      });
  }, []);

  const imgArr = [img2, img4, img9];
  var swiperIndex = 0;
  var swiperWay = -1;
  var swipeCurrent = 359;
  const [trainPosition, setTrainPosition] = useState(0);
  const trainRef = useRef();
  useEffect(() => {
    localStorage.setItem("type", "Услуги");
    var swiperTrainPartWidth = trainRef.current.scrollWidth / 3;
    // var swiperTrainIndex = trainRef.current.scrollLeft/swiperTrainPartWidth
    // var swiperTrainPartsLength = document.getElementsByClassName("services_actions").length;
    var handleInterval = setInterval(() => {
      if (swiperIndex === -2 && swiperWay === -1) {
        swiperWay = 1;
      } else if (swiperIndex === 0 && swiperWay === 1) {
        swiperWay = -1;
      }
      swiperIndex = swiperIndex + swiperWay;
      swipeCurrent = swiperTrainPartWidth * swiperIndex;
      trainRef.current.style.transform = `translateX(${swipeCurrent}px)`;
      setTrainPosition(swiperIndex * -1);
    }, 8000);
    return () => clearInterval(handleInterval);
  }, []);
  console.log(services);
  return (
    <AnimatedPage>
      <div className="services_surgeons_page_container">
        <YourQuestionsHeader />
        <div className="services_swiper_box">
          <div className="services_swiper" ref={trainRef}>
            {imgArr.map((img, index) => (
              <div key={index} className="services_actions">
                <img className="actions_img" src={img} alt="" />
                <Link
                  to="/salesRollin"
                  className="actions_button"
                  onClick={() => {
                    localStorage.setItem("actionId", index);
                    localStorage.setItem("changeMidTypeText", true);
                  }}
                >
                  <button
                    tabIndex="0"
                    className="btnLeft sales_rollin_button big_mover_button_color_disabled"
                  >
                    Записаться
                  </button>
                </Link>
              </div>
            ))}
          </div>
          <div className="dots_box">
            <div
              className={
                trainPosition === 0
                  ? "slider_dot_active"
                  : "slider_dot_disabled"
              }
            ></div>
            <div
              className={
                trainPosition === 1
                  ? "slider_dot_active"
                  : "slider_dot_disabled"
              }
            ></div>
            <div
              className={
                trainPosition === 2
                  ? "slider_dot_active"
                  : "slider_dot_disabled"
              }
            ></div>
          </div>
        </div>
        <div className="services_surgeons_page_content_padding">
          {services &&
            services.map((em, index) => {
              console.log("fff");
              return (
                <div key={index}>
                  <p className="search_services_first_section">{em.type}</p>
                  {em.cards &&
                    em.cards.length > 0 &&
                    em.cards.map((elem, index2) => {
                      return (
                        <ServicesSection
                          key={index2}
                          title={elem.nameService}
                          money={elem.price}
                        />
                      );
                    })}
                </div>
              );
            })}
        </div>
      </div>
    </AnimatedPage>
  );
}
