import "./alldoctorsPage.css";
import YourQuestionsHeader from "../../components/yourQuestionsHeader/yourQuests";
import LeadersButton from "../../components/leadersButton/leadersButton";
import SurgeonsButton from "../../components/surgeonsButton/surgeonsButton";
import OrthodontistsButton from "../../components/orthodontistsButton/orthodontistsButton";
import OrthodontistsSecButton from "../../components/orthodontistsSecButton/orthodontistsSecButton";
import OrthopedistsButton from "../../components/orthopedistsButton/orthopedistsButton";
import PediatricianButton from "../../components/pediatricianButton/pediatricianButton";
import DoctorAboutCard from "../../components/doctorAboutCards/doctorAboutCard";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import AnimatedPage from "../AnimatedPage"
import TerapephtsButton from "../../components/terapephtsButton/terapephtsButton";
import GigienistsButton from "../../components/gigienistsButton/gigienistsButton";
import ParadontologsButton from "../../components/paradontologsButton/paradontologsButton";
import ImpantologsButton from "../../components/impantologsButton/impantologsButton";
import GnatologsButton from "../../components/gnatologsButton/gnatologsButton";
import BackToStarterButton from "../../components/backToStarterButton/backToStarterButton";


export default function AlldoctorsPage() {
    // const [currentTypeCategories, setCurrentTypeCategories] = useState(1)
    // const doctorTypeSwiper = useRef(null);
    // useEffect(()=>{
    //     doctorTypeSwiper.current.addEventListener("scroll",scrollHandler)
    //     doctorCardSwiper.current.addEventListener("scroll",cardScrollHandler)
    //     console.log(doctorCardSwiper.current.scrollWidth)
    // },[])
    // const scrollHandler=()=>{
    //     let sectionWidth=doctorTypeSwiper.current.scrollWidth/3;
    //     let sectionIndex=Math.floor(doctorTypeSwiper.current.scrollLeft/sectionWidth)
    //     if(doctorTypeSwiper.current.scrollLeft===sectionWidth*sectionIndex){
    //         setCurrentTypeCategories(sectionIndex+1)
    //         console.log(doctorTypeSwiper.current.scrollLeft,sectionWidth,Math.floor(doctorTypeSwiper.current.scrollLeft/sectionWidth),sectionIndex)

    //     }
    // }
    // const [currentCardCategories, setCurrentCardCategories] = useState(1)
    // const doctorCardSwiper = useRef(null);
    // const cardWidth = useRef(null);
    // const cardScrollHandler=()=>{
    //     console.log(cardWidth.current)
    //     let sectionCardWidth= window.document.getElementsByClassName("doctorabout_card_container")[0].clientWidth
    //     let sectionCardIndex=Math.floor(doctorCardSwiper.current.scrollLeft/sectionCardWidth)
    //     if(doctorCardSwiper.current.scrollLeft>=sectionCardWidth){
    //         setCurrentCardCategories(Math.floor(doctorCardSwiper.current.scrollLeft/sectionCardWidth+1))
    //         console.log(doctorCardSwiper.current.scrollLeft,sectionCardWidth,currentTypeCategories,Math.floor(doctorCardSwiper.current.scrollLeft/sectionCardWidth),sectionCardIndex)
    //     }
    //     else{
    //         setCurrentCardCategories(1)
    //     }
    //     console.log(doctorCardSwiper.current.scrollLeft,sectionCardWidth,currentCardCategories,Math.floor(doctorCardSwiper.current.scrollLeft/sectionCardWidth),sectionCardIndex)
    // }
    const [startAnimation, setStartAnimation] = useState(false);
    useEffect(()=>{
        setTimeout(() => {
          setStartAnimation(true)
        }, 300);
        if(localStorage.getItem("starterEnroll")=="true"){
            localStorage.setItem("HeaderBackLink", "/startRollin")
        }
      },[])

    return (
    <AnimatedPage>
      <div className="alldoctors_page_container">
        <YourQuestionsHeader/>
         
        {/* <div className="crazy_train"> */}
            <div className="doctor_type_swiper">
                <div className="row_doctor_type_swiper" style={startAnimation ? {transform: "scale(1)"} : {transform: "scale(0)"}}>
                    <Link to="/docslist"><LeadersButton/></Link>
                    <Link to="/docslist"><SurgeonsButton/></Link>
                    <Link to="/docslist"><OrthodontistsButton/></Link>
                </div>
                <div className="row_doctor_type_swiper row_doctor_type_swiper2" style={startAnimation ? {transform: "scale(1)"} : {transform: "scale(0)"}}>
                    <Link to="/docslist"><OrthopedistsButton/></Link>
                    <Link to="/docslist"><OrthodontistsSecButton/></Link>
                    <Link to="/docslist"><PediatricianButton/></Link>
                </div>
                <div className="row_doctor_type_swiper row_doctor_type_swiper3" style={startAnimation ? {transform: "scale(1)"} : {transform: "scale(0)"}}>
                    <Link to="/docslist"><TerapephtsButton/></Link>
                    <Link to="/docslist"><GigienistsButton/></Link>
                    <Link to="/docslist"><ParadontologsButton/></Link>
                </div>
                <div className="row_doctor_type_swiper row_doctor_type_swiper4" style={startAnimation ? {transform: "scale(1)"} : {transform: "scale(0)"}}>
                    <Link to="/docslist"><ImpantologsButton/></Link>
                    <Link to="/docslist"><GnatologsButton/></Link>
                </div>
            </div>
            {/* <div className="doctor_type_swiper">
                <div className="first_row_doctor_type_swiper">
                    <LeadersButton/>
                    <SurgeonsButton/>
                    <OrthodontistsButton/>
                </div>
                <div className="second_row_doctor_type_swiper">
                    <OrthopedistsButton/>
                    <OrthodontistsSecButton/>
                    <PediatricianButton/>
                </div>
                <div className="swipe_controller">
                    
                </div>
            </div>
            <div className="doctor_type_swiper">
                <div className="first_row_doctor_type_swiper">
                    <LeadersButton/>
                    <SurgeonsButton/>
                    <OrthodontistsButton/>
                </div>
                <div className="second_row_doctor_type_swiper">
                    <OrthopedistsButton/>
                    <OrthodontistsSecButton/>
                    <PediatricianButton/>
                </div>
                <div className="swipe_controller">
                    
                </div>
            </div>
        </div>
        <div className="train_controller_upper">
            <div className={currentTypeCategories === 1 ? "train_upper_station_active":"train_upper_station_disabled"}></div>
            <div className={currentTypeCategories === 2 ? "train_upper_station_active":"train_upper_station_disabled"}></div>
            <div className={currentTypeCategories === 3 ? "train_upper_station_active":"train_upper_station_disabled"}></div>
        </div> */}
      </div>
      </AnimatedPage>
    );
  }