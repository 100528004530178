import "./backToDatesButton.css";

import backToSearchArrowImg from "../../img/backToSearchArrow.png";


export default function BackToDatesButton(){
    const WindowBack=()=>{
        window.history.back()
    }
    return(
        <div tabIndex="0" onClick={WindowBack} className="btnLeft back_to_dates_container">

            <img src={backToSearchArrowImg}/>
            <p>Выбор даты</p>
        </div>
    )
}