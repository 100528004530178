import "./doctorInfoPage.css";
import infoIcon from "../../icons/stats.svg";
import smallCircleImg from "../../img/doctorInfoCircleSmall.png";
import bigCircleImg from "../../img/doctorInfoCircleBig.png";
import doctorProfilePhotoImg from "../../img/doctorInfoProfilePhoto.png";
import moreInfoArrow from "./moreInfoArrow.svg";
import AnimatedPage from "../AnimatedPage";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../Context";
import EnrollBottomBlueButton from "../../components/enrollBottomBlueButton/enrollBottomBlueButton";
import { Id } from "../../const";
import img0 from "./1.png";
import img1 from "./2.png";
import img2 from "./3.png";
import img3 from "./4.png";
import img4 from "./5.png";
import img5 from "./6.png";
import img6 from "./7.png";
import { useEffect } from "react";
const arrImg = [img0, img1, img2, img3, img4, img5, img6];
export default function DoctorInfoPage() {
  const [openedMoreInfo, setOpenedMoreInfo] = useState(false);
  const upperInfoRef = useRef();
  const downerInfoRef = useRef();
  const moreInfoButtonRef = useRef();
  const downerButtonBoxRef = useRef();
  const backSmallInfoRef = useRef();
  const jobNameRef = useRef();
  const infoRef = useRef();
  const doctors = useContext(Context);
  const urlParams = new URLSearchParams(window.location.search);
  const doctorId = Number(urlParams.get("id"));

  const openDoctorInfo = () => {
    upperInfoRef.current.style.height = "0%";
    downerInfoRef.current.style.height = "100%";
    moreInfoButtonRef.current.style.opacity = "0";
    jobNameRef.current.style.opacity = "0";
    infoRef.current.style.opacity = "0";
    backSmallInfoRef.current.style.opacity = "0";
    downerButtonBoxRef.current.style.opacity = "0";
    setTimeout(() => {
      setOpenedMoreInfo(true);
      backSmallInfoRef.current.innerHTML = "Вернуться";
      backSmallInfoRef.current.style.opacity = "1";
      downerButtonBoxRef.current.style.opacity = "1";
    }, 500);
    setTimeout(() => {
      jobNameRef.current.style.opacity = "1";
      infoRef.current.style.opacity = "1";
    }, 750);
  };
  const WindowBack = () => {
    window.history.back();
  };
  const HeightBack = () => {
    upperInfoRef.current.style.height = "47%";
    downerInfoRef.current.style.height = "65%";
    backSmallInfoRef.current.style.opacity = "0";
    jobNameRef.current.style.opacity = "0";
    infoRef.current.style.opacity = "0";
    backSmallInfoRef.current.style.opacity = "0";
    downerButtonBoxRef.current.style.opacity = "0";
    setTimeout(() => {
      setOpenedMoreInfo(false);
      backSmallInfoRef.current.innerHTML = "Вернуться к выбору врача";
      backSmallInfoRef.current.style.opacity = "1";
      downerButtonBoxRef.current.style.opacity = "1";
    }, 500);
    setTimeout(() => {
      moreInfoButtonRef.current.style.opacity = "1";
      jobNameRef.current.style.opacity = "1";
      infoRef.current.style.opacity = "1";
    }, 850);
  };
  console.log(doctors.length > 0 && doctors[0].name);
  return (
    <AnimatedPage>
      {doctors && doctors.length > 0 && doctors[doctorId] && (
        <div className="doctor_info_container">
          {/* <img className="doctor_info_backgorund_addons" src={smallCircleImg}/>
            <img className="doctor_info_backgorund_addons" src={bigCircleImg}/>
            <img className="doctor_info_backgorund_addons" src={doctorProfilePhotoImg}/> */}
          <div className="doctor_info_photo">
            <img
              src={
                doctors &&
                doctors.length > 0 &&
                doctors[doctorId] &&
                doctors[doctorId].photoCard
              }
              alt=""
              className="doctor__img"
            />
            <div className="doctor_info_upper" ref={upperInfoRef}>
              <p className="doctor_info_name">
                {doctors && doctors.length > 0 ? doctors[doctorId].name : ""}
              </p>
              <p className="doctor_info_jobname">
                {doctors && doctors.length > 0
                  ? doctors[doctorId].education
                  : ""}
              </p>
              {doctors &&
                doctors.length &&
                doctors[doctorId] &&
                doctors[doctorId].servicesNum != false && (
                  <div className="doctor_info_patients">
                    <img src={infoIcon} className="doctor_info_icons" />
                    <div className="doctor_info_patiens_amount">
                      <p>
                        {doctors && doctors.length > 0
                          ? doctors[doctorId].servicesNum
                          : ""}
                      </p>
                      <p>Пациентов</p>
                    </div>
                  </div>
                )}
            </div>
            <div
              className="doctor_info_downer"
              ref={downerInfoRef}
              style={
                doctors && doctors.length && doctors[doctorId].info.length > 0
                  ? { height: "65%" }
                  : { height: "45%" }
              }
            >
              <div className="doctor_info_downer_text_box">
                <p className="doctor_info_downer_name">
                  {doctors && doctors.length > 0 ? doctors[doctorId].name : ""}
                </p>
                <p>
                  {doctors && doctors.length > 0
                    ? doctors[doctorId].education
                    : ""}
                </p>
                {doctors &&
                  doctors.length > 0 &&
                  doctors[doctorId].info.length > 0 && (
                    <p className="doctor_info_downer_fullinfo_title">
                      Информация о враче
                    </p>
                  )}
                {doctors &&
                  doctors.length > 0 &&
                  doctors[doctorId].info.length > 0 && (
                    <p className="doctor_info_downer_infotext" ref={infoRef}>
                      {doctors && doctors.length > 0
                        ? doctors[doctorId].info
                        : ""}
                    </p>
                  )}
              </div>
              <div
                className="doctor_info_downer_buttons_box"
                ref={downerButtonBoxRef}
              >
                <Link to={"/rollinDoctorPage?id=" + doctorId}>
                  <EnrollBottomBlueButton />
                </Link>
                <div
                  tabIndex="0"
                  onClick={openedMoreInfo ? HeightBack : WindowBack}
                  className="btnRight doctor_info_downer_back_button"
                  ref={backSmallInfoRef}
                >
                  Вернуться к выбору врача
                </div>
                <div className="down_padding"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AnimatedPage>
  );
}
