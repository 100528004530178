import "./servicesSection.css";
import { Link } from "react-router-dom";

export default function ServicesSection(props) {
  return (
    <div className="services_section_container">
      <div className="section_about">
        <p className="section_title">{props.title}</p>
        <p className="section_money">
          {props.money == "-" ? "Бесплатно" : props.money + " руб."}
        </p>
      </div>
      <Link
        onClick={() => {
          localStorage.setItem("typeServices", props.title);
        }}
        to="/fillYourNameServices"
      >
        <button className="services_surgeons_rollin big_mover_button_color_disabled">
          Записаться
        </button>
      </Link>
    </div>
  );
}
