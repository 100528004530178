import "./doctorAboutCard.css";
import toothdoctorImg from "../../img/toothdoctor.png";
import { Link } from "react-router-dom";
export default function DoctorAboutCard({ img, title, subTitle, summ }) {
  console.log(summ);
  return (
    <div tabIndex="0" className="btnMid doctorabout_card_container">
      <img src={img} />
      <div className="doctor_info">
        <p className="doctor_name">{title}</p>
        <p className="doctor_jobname">{subTitle}</p>
        {summ == true && <p className="doctor_jobs_done">Выполнено</p>}

        {summ == true && <p className="doctor_jobs_amount">{summ} процедур</p>}
      </div>
      <div className="doctor_card_flag">
        <div></div>
      </div>
    </div>
  );
}
