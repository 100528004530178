import "./startRollinPage.css";
import salesRollBackground from "../../img/salesRollBackground.png";
import { Link, useNavigate } from "react-router-dom";
import backToSearchArrowImg from "../../img/backToSearchArrow.png";
import TimeChoser from "../../components/timeChoser/timeChoser";
import AnimatedPage from "../AnimatedPage";
import { useEffect, useRef, useState } from "react";
import EnrollBottomBlueButton from "../../components/enrollBottomBlueButton/enrollBottomBlueButton";
import startRollinManIcon from "../../img/startRollinManIcon.png";
import startRollinPhoneIcon from "../../img/startRollinPhoneIcon.png";
import startRollinDateIcon from "../../img/startRollinDateIcon.png";
import { Id } from "../../const";
export default function StartRollinNew() {
  const [startRollinLink, setStartRollinLink] = useState(false);
  const ChoserContainer = useRef(null);
  const rollinTypeFirst = useRef(null);
  const rollinTypeSecond = useRef(null);
  const [whatTypeToSearch, setWhatTypeToSearch] = useState("");
  const setActiveFirst = () => {
    console.log(whatTypeToSearch);
    rollinTypeFirst.current.className =
      "start_rollin_type_active start_rollin_type";
    rollinTypeSecond.current.className =
      "start_rollin_type_disabled start_rollin_type";
    setWhatTypeToSearch("/servicesDoctorsPage");
  };
  const setActiveSecond = () => {
    console.log(whatTypeToSearch);
    rollinTypeSecond.current.className =
      "start_rollin_type_active start_rollin_type";
    rollinTypeFirst.current.className =
      "start_rollin_type_disabled start_rollin_type";
    setWhatTypeToSearch("/servicesSurgeonsPage");
  };
  const WindowBack = () => {
    window.history.back();
  };
  const Navigate = useNavigate();
  const navigateNext = (path, event) => {
    Navigate(path);
  };

  useEffect(() => {
    localStorage.setItem("type", "Прямая запись");
  }, []);
  // const ActiveDisabledLink = props.linkButton;
  const [currentTimeCategories, setCurrentTimeCategories] = useState(0);
  const [datePlaceholder, setDatePlaceholder] = useState(true);
  const TimeChoser = useRef(null);
  const TimeBox = useRef(null);
  const ChooseTimeBox = useRef(null);
  const DayRef = useRef(null);
  const FioRef = useRef(null);
  const TelRef = useRef(null);

  const [form, setForm] = useState({
    tel: localStorage.chosenTel ? localStorage.chosenTel : "+7",
    fio: localStorage.chosenFio ? localStorage.chosenFio : "",
  });
  console.log(form);
  const chosenDayTime = {
    day: "",
    time: "",
    fio: "",
    tel: "",
  };
  console.log(chosenDayTime);
  useEffect(() => {
    if (localStorage.length != 0) {
      if (localStorage.chosenTime) {
        TimeChoser.current.innerHTML = localStorage.getItem("chosenTime");
        chosenDayTime.time = localStorage.getItem("chosenTime");
        DayTimeHandler();
      } else {
        TimeChoser.current.innerHTML = "Выберите время";
      }
      if (localStorage.chosenDay) {
        DayRef.current.value = localStorage.getItem("chosenDay");
        chosenDayTime.day = localStorage.getItem("chosenDay");
        DayTimeHandler();
      }
      if (localStorage.chosenTel) {
        TelRef.current.value = localStorage.getItem("chosenTel");
        chosenDayTime.tel = localStorage.getItem("chosenTel");
        DayTimeHandler();
      }
      if (localStorage.chosenFio) {
        FioRef.current.value = localStorage.getItem("chosenFio");
        chosenDayTime.fio = localStorage.getItem("chosenFio");
        DayTimeHandler();
      }
    }
    ChoserContainer.current.addEventListener("click", (event) => {
      if (
        event.target.classList.contains("full_time_choser") ||
        event.target.classList.contains("choose_time_innerhtml_start_rollin")
      ) {
        ChooseTimeBox.current.style = "display: flex;";
      }
      if (event.target.className === "time_choser") {
        TimeChoser.current.innerHTML = event.target.innerHTML;
        localStorage.setItem("chosenTime", event.target.innerHTML);
        DayTimeHandler();
      }
      if (!event.target.classList.contains("not_close")) {
        ChooseTimeBox.current.style = "display: none;";
      }
    });
  }, []);
  const [errorText, seterrorText] = useState("");
  const scrollRight = () => {
    let sectionWidth = TimeBox.current.scrollWidth;
    TimeBox.current.style = `transform: translateX(-${sectionWidth / 2}px)`;
    setCurrentTimeCategories(1);
  };
  const scrollLeft = () => {
    setCurrentTimeCategories(0);
    TimeBox.current.style = `transform: translateX(0px)`;
  };
  const DateHandler = () => {
    localStorage.setItem("chosenDay", DayRef.current.value);
    chosenDayTime.day = localStorage.getItem("chosenDay");
    DayTimeHandler();
  };
  const DayTimeHandler = () => {
    if (chosenDayTime.fio.length >= 2 && chosenDayTime.tel.length === 17) {
      setStartRollinLink(true);
    }
    if (
      chosenDayTime.fio.length < 2 ||
      chosenDayTime.tel.length < 17 ||
      (!localStorage.chosenDay && !localStorage.chosenTime)
    ) {
      setStartRollinLink(false);
    }
  };
  function mask(event) {
    let keyCode;
    event.keyCode && (keyCode = event.keyCode);
    let pos = event.target.selectionStart;
    if (pos < 3) event.preventDefault();
    let matrix = "+7 (___) ___ ____",
      i = 0,
      def = matrix.replace(/\D/g, ""),
      val = event.target.value.replace(/\D/g, ""),
      new_value = matrix.replace(/[_\d]/g, function (a) {
        return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
      });
    i = new_value.indexOf("_");
    if (i != -1) {
      i < 5 && (i = 3);
      new_value = new_value.slice(0, i);
    }
    let reg = matrix
      .substr(0, event.target.value.length)
      .replace(/_+/g, function (a) {
        return "\\d{1," + a.length + "}";
      })
      .replace(/[+()]/g, "\\$&");
    reg = new RegExp("^" + reg + "$");
    if (
      !reg.test(event.target.value) ||
      event.target.value.length < 5 ||
      (keyCode > 47 && keyCode < 58)
    )
      event.target.value = new_value;
    if (event.type == "blur" && event.target.value.length < 5)
      event.target.value = "";
  }

  const handleChange = (event) => {
    switch (event.target.name) {
      case "tel":
        mask(event);
        setForm({
          ...form,
          tel: event.target.value,
        });
        localStorage.setItem("chosenTel", TelRef.current.value);
        DayTimeHandler();
        break;
      case "fio":
        setForm({
          ...form,
          fio: event.target.value,
        });
        localStorage.setItem("chosenFio", FioRef.current.value);
        DayTimeHandler();
        break;
    }
  };
  const starterEnroll = () => {
    console.log(form, chosenDayTime);
    let formData = new FormData();
    formData.append("name", localStorage.chosenFio);
    formData.append("phone", localStorage.chosenTel);
    formData.append("type", localStorage.type);
    formData.append("date", localStorage.chosenDay);
    formData.append("time", localStorage.chosenTime);
    fetch("https://techdom1.store/bot.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      formData.append("ID", Id);
      fetch("https://techdom1.store/addApp.php", {
        method: "POST",
        body: formData,
      });
      Navigate("/rollinFinal");
    });
  };
  return (
    <AnimatedPage>
      <div className="start_rollin_page_container" ref={ChoserContainer}>
        <div
          tabIndex="0"
          className="btnLeft back_to_starter"
          onClick={WindowBack}
        >
          <img src={backToSearchArrowImg} />
          На главную
        </div>
        <img className="salesRollBackground" src={salesRollBackground} />
        <div className="startRollinNew_inputs_box">
          <p className="fill_patient_title">Заполните данные о пациенте</p>
          <div className="fio_phone_input_box">
            <div className="icons_box">
              <img className="startRollinManIcon" src={startRollinManIcon} />
            </div>
            <input
              className="fio_phone_inputs"
              type="text"
              name="fio"
              onChange={handleChange}
              ref={FioRef}
              placeholder="Ваши ФИО"
            ></input>
          </div>
          <div className="fio_phone_input_box">
            <div className="icons_box">
              <img
                className="startRollinPhoneIcon"
                src={startRollinPhoneIcon}
              />
            </div>
            <input
              className="fio_phone_inputs"
              type="text"
              name="tel"
              onChange={handleChange}
              ref={TelRef}
              placeholder="Телефон"
            ></input>
          </div>
          <div className="fio_phone_input_box">
            <div className="icons_box">
              <img className="startRollinDateIcon" src={startRollinDateIcon} />
            </div>
            <div className="date_time_inputs_start_rollin">
              <div className="date_input_placeholder_start_rollin">
                <input
                  type="date"
                  className="date_input_start_rollin"
                  ref={DayRef}
                  style={datePlaceholder ? { color: "#fff" } : {}}
                  onChange={DateHandler}
                  onFocus={() => setDatePlaceholder(false)}
                ></input>
                {datePlaceholder && (
                  <div className="date_placeholder_start_rollin">
                    Выберите дату
                  </div>
                )}
              </div>
              <div className="full_time_choser_start_rollin not_close">
                <div
                  className="choose_time_innerhtml_start_rollin not_close"
                  ref={TimeChoser}
                >
                  Выберите время
                </div>
                <div className="choose_time_box not_close" ref={ChooseTimeBox}>
                  <p className=" not_close">Выберите время</p>
                  <div className="train_window">
                    <div className="columns_train" ref={TimeBox}>
                      <div className="columns_box">
                        <div className="choose_time_column">
                          <p className="time_choser">8:00-8:30</p>
                          <p className="time_choser">9:30-10:00</p>
                          <p className="time_choser">11:00-11:30</p>
                          <p className="time_choser">12:30-13:00</p>
                          <p className="time_choser">14:00-14:30</p>
                          <p className="time_choser">15:30-16:00</p>
                        </div>
                        <div className="choose_time_column">
                          <p className="time_choser">8:30-9:00</p>
                          <p className="time_choser">10:00-10:30</p>
                          <p className="time_choser">11:30-12:30</p>
                          <p className="time_choser">13:00-13:30</p>
                          <p className="time_choser">14:30-15:00</p>
                          <p className="time_choser">16:00-16:30</p>
                        </div>
                        <div className="choose_time_column">
                          <p className="time_choser">9:00-9:30</p>
                          <p className="time_choser">10:30-11:00</p>
                          <p className="time_choser">12:00-12:30</p>
                          <p className="time_choser">13:30-14:00</p>
                          <p className="time_choser">15:00-15:30</p>
                          <p className="time_choser">16:30-17:00</p>
                        </div>
                      </div>
                      <div className="columns_box">
                        <div className="choose_time_column">
                          <p className="time_choser">8:00-8:30</p>
                          <p className="time_choser">9:30-10:00</p>
                          <p className="time_choser">11:00-11:30</p>
                          <p className="time_choser">12:30-13:00</p>
                          <p className="time_choser">14:00-14:30</p>
                          <p className="time_choser">15:30-16:00</p>
                        </div>
                        <div className="choose_time_column">
                          <p className="time_choser">8:30-9:00</p>
                          <p className="time_choser">10:00-10:30</p>
                          <p className="time_choser">11:30-12:30</p>
                          <p className="time_choser">13:00-13:30</p>
                          <p className="time_choser">14:30-15:00</p>
                          <p className="time_choser">16:00-16:30</p>
                        </div>
                        <div className="choose_time_column">
                          <p className="time_choser">9:00-9:30</p>
                          <p className="time_choser">10:30-11:00</p>
                          <p className="time_choser">12:00-12:30</p>
                          <p className="time_choser">13:30-14:00</p>
                          <p className="time_choser">15:00-15:30</p>
                          <p className="time_choser">16:30-17:00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="choose_time_arrows_box not_close">
                    <div
                      onClick={scrollLeft}
                      className={
                        currentTimeCategories === 1
                          ? "choose_time_arrows_icons_active choose_time_arrows_icons not_close"
                          : "choose_time_arrows_icons_disabled choose_time_arrows_icons not_close"
                      }
                    >
                      &#8249;
                    </div>
                    <div
                      onClick={scrollRight}
                      className={
                        currentTimeCategories === 0
                          ? "choose_time_arrows_icons_active choose_time_arrows_icons not_close"
                          : "choose_time_arrows_icons_disabled choose_time_arrows_icons not_close"
                      }
                    >
                      &#8250;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Link
            className={
              form.tel.length > 5 && form.fio.length > 2
                ? "enroll_blue_bottom_button"
                : "enroll_grey_bottom_button"
            }
            onClick={
              form.tel.length > 5 && form.fio.length > 2 ? starterEnroll : ""
            }
          >
            Записаться
          </Link>
          <div className="checkbox_input_text">
            <input type="checkbox" checked></input>
            <p className="checkbox_input_text_text">
              Даю согласие на <span>обработку персональных данных</span>
            </p>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}
