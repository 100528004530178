import "./orthodontistsButton.css"
import toothchairImg from "../../img/toothchair.png"

export default function OrthodontistsButton() {
    return (
      <div tabIndex="0" className="btnRight orthodontists_button_container doctor_swiper_button small_mover_button_color_disabled">
        <img src={toothchairImg}/>
        <p>Ортодонты</p>
      </div>
    );
  }