import phoneIcon from "../../icons/bluephone.svg";
import backToStartImg from "../../img/backToStartArrow.png";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./yourQuests.css";
export default function YourQuestionsHeader() {
  const WindowBack = () => {
    window.history.back();
  };
  const innerHTML = useRef(null);
  const copiedBox = useRef(null);
  const copytext = (e) => {
    innerHTML.current.select();
    document.execCommand("copy");
    copiedBox.current.style.bottom = "3vh";
    setTimeout(() => {
      copiedBox.current.style.bottom = "-15vh";
    }, 2000);
  };
  return (
    <div className="your_questions">
      <div
        onClick={WindowBack}
        tabIndex={0}
        className="btnLeft arrow_back_header"
      >
        <img src={backToStartImg} />
        <p>Назад</p>
      </div>
      <div className="question_phone">
        <p>Задать вопрос</p>
        <div onClick={copytext} className="your_quests_phone">
          <img src={phoneIcon} />
          <input ref={innerHTML} value={"8(926) 694-24-91"} readOnly></input>
        </div>
      </div>
      <div ref={copiedBox} className="copied_phone">
        Номер телефона <br /> скопирован
      </div>
    </div>
  );
}
