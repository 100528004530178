import "./timeChoser.css";
import { useEffect, useRef, useState } from "react";

export default function TimeChoser(props) {
  const ChoserContainer = props.choserContainerRef;
  const setStartRollinLink = props.setStartRollinLink;
  // const ActiveDisabledLink = props.linkButton;
  const [currentTimeCategories, setCurrentTimeCategories] = useState(0);
  const [datePlaceholder, setDatePlaceholder] = useState(true);
  const TimeChoser = useRef(null);
  const TimeBox = useRef(null);
  const ChooseTimeBox = useRef(null);
  const DayRef = useRef(null);
  const chosenDayTime = {
    day: "",
    time: "",
  };
  useEffect(() => {
    if (localStorage.length != 0) {
      if (localStorage.getItem("chosenTime") != null) {
        TimeChoser.current.innerHTML = localStorage.getItem("chosenTime");
        chosenDayTime.time = localStorage.getItem("chosenTime");
      }
      if (localStorage.getItem("chosenDay") != null) {
        DayRef.current.value = localStorage.getItem("chosenDay");
        chosenDayTime.day = localStorage.getItem("chosenDay");
      }
    } else {
      TimeChoser.current.innerHTML = "Выберите время";
    }
    if (chosenDayTime.time != "" && chosenDayTime.day != "") {
      setStartRollinLink(true);
    }
    ChoserContainer.current.addEventListener("click", (event) => {
      if (
        event.target.classList.contains("full_time_choser") ||
        event.target.classList.contains("choose_time_innerhtml")
      ) {
        ChooseTimeBox.current.style = "display: flex;";
      }
      if (event.target.className === "time_choser") {
        TimeChoser.current.innerHTML = event.target.innerHTML;
        localStorage.setItem("chosenTime", event.target.innerHTML);
        DayTimeHandler();
      }
      if (!event.target.classList.contains("not_close")) {
        ChooseTimeBox.current.style = "display: none;";
      }
    });
  }, []);
  const scrollRight = () => {
    let sectionWidth = TimeBox.current.scrollWidth;
    TimeBox.current.style = `transform: translateX(-${sectionWidth / 2}px)`;
    setCurrentTimeCategories(1);
  };
  const scrollLeft = () => {
    setCurrentTimeCategories(0);
    TimeBox.current.style = `transform: translateX(0px)`;
  };
  const DateHandler = () => {
    localStorage.setItem("chosenDay", DayRef.current.value);
    chosenDayTime.day = localStorage.getItem("chosenDay");
    DayTimeHandler();
  };
  const DayTimeHandler = () => {
    chosenDayTime.day = localStorage.getItem("chosenDay");
    chosenDayTime.time = localStorage.getItem("chosenTime");
    if (chosenDayTime.day != null && chosenDayTime.time != null) {
      setStartRollinLink(true);
    }
    if (chosenDayTime.day == "" || chosenDayTime.time == "") {
      setStartRollinLink(false);
    }
  };
  return (
    <div className="date_time_inputs">
      <div className="date_input_placeholder">
        <input
          type="date"
          className="date_input"
          style={datePlaceholder ? { color: "#fff" } : {}}
          ref={DayRef}
          onChange={DateHandler}
          onFocus={() => setDatePlaceholder(false)}
        ></input>
        {datePlaceholder && (
          <div className="date_placeholder">Выберите дату</div>
        )}
      </div>
      <div className="full_time_choser not_close">
        <div className="choose_time_innerhtml not_close" ref={TimeChoser}>
          Выберите время
        </div>
        <div className="choose_time_box not_close" ref={ChooseTimeBox}>
          <p className=" not_close">Выберите время</p>
          <div className="train_window not_close">
            <div className="columns_train not_close" ref={TimeBox}>
              <div className="columns_box not_close">
                <div className="choose_time_column not_close">
                  <p className="time_choser">9:00-9:30</p>
                  <p className="time_choser">10:30-11:00</p>
                  <p className="time_choser">12:00-12:30</p>
                  <p className="time_choser">13:30-14:00</p>
                  <p className="time_choser">15:00-15:30</p>
                  <p className="time_choser">16:30-17:00</p>
                </div>
                <div className="choose_time_column not_close">
                  <p className="time_choser">9:30-10:00</p>
                  <p className="time_choser">11:00-11:30</p>
                  <p className="time_choser">12:30-13:00</p>
                  <p className="time_choser">14:00-14:30</p>
                  <p className="time_choser">15:30-16:00</p>
                  <p className="time_choser">17:00-17:30</p>
                </div>
                <div className="choose_time_column not_close">
                  <p className="time_choser">10:00-10:30</p>
                  <p className="time_choser">11:30-12:00</p>
                  <p className="time_choser">13:00-13:30</p>
                  <p className="time_choser">14:30-15:00</p>
                  <p className="time_choser">16:00-16:30</p>
                  <p className="time_choser">17:30-18:00</p>
                </div>
              </div>
              <div className="columns_box not_close">
                <div className="choose_time_column not_close">
                  <p className="time_choser">18:00-18:30</p>
                  <p className="time_choser">19:30-20:00</p>
                  {/* <p className="time_choser">11:00-11:30</p>
                                        <p className="time_choser">12:30-13:00</p>
                                        <p className="time_choser">14:00-14:30</p>
                                        <p className="time_choser">15:30-16:00</p> */}
                </div>
                <div className="choose_time_column not_close">
                  <p className="time_choser">18:30-19:00</p>
                  <p className="time_choser">20:00-20:30</p>
                  {/* <p className="time_choser">11:30-12:30</p>
                                        <p className="time_choser">13:00-13:30</p>
                                        <p className="time_choser">14:30-15:00</p>
                                        <p className="time_choser">16:00-16:30</p> */}
                </div>
                <div className="choose_time_column not_close">
                  <p className="time_choser">19:00-19:30</p>
                  <p className="time_choser">20:30-21:00</p>
                  {/* <p className="time_choser">12:00-12:30</p>
                                        <p className="time_choser">13:30-14:00</p>
                                        <p className="time_choser">15:00-15:30</p>
                                        <p className="time_choser">16:30-17:00</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="choose_time_arrows_box not_close">
            <div
              onClick={scrollLeft}
              className={
                currentTimeCategories === 1
                  ? "choose_time_arrows_icons_active choose_time_arrows_icons not_close"
                  : "choose_time_arrows_icons_disabled choose_time_arrows_icons not_close"
              }
            >
              &#8249;
            </div>
            <div
              onClick={scrollRight}
              className={
                currentTimeCategories === 0
                  ? "choose_time_arrows_icons_active choose_time_arrows_icons not_close"
                  : "choose_time_arrows_icons_disabled choose_time_arrows_icons not_close"
              }
            >
              &#8250;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
