import "./examplesCard.css"
import { Link } from "react-router-dom";

export default function ExamplesCard(props){
    return(
        <div className="examples_card_container">
            <img className="examples_card_img" src={props.img}/>
            <div className="example_card_text">
                <p className="example_card_text_title">{props.title}</p>
                <p className="example_card_text_name">{props.name}</p>
                <p className="example_card_text_info">{props.text}</p>
                <Link to="/examplesAboutInfo"> <button tabIndex="0" className="btnLeft example_card_text_rollin big_mover_button_color_disabled">Смотреть</button></Link>
            
            </div>
        </div>
    )
}