import "./salesRollinPage.css";
import BackToSearchButton from "../../components/backToSearchButton/backToSearchButton";
import { Link } from "react-router-dom";
import AnimatedPage from "../AnimatedPage";
import salesPlashka from "../../img/salesRollinPlashka.png";
import chooseDateTimeImg from "../../img/chooseDateTime.png";
import TimeChoser from "../../components/timeChoser/timeChoser";
import { useEffect, useRef, useState } from "react";
import EnrollBottomBlueButton from "../../components/enrollBottomBlueButton/enrollBottomBlueButton";
import SalesMidText from "../../components/salesMidText/salesMidText";
import SalesServicesMidText from "../../components/salesMidText/salesServicesMidText";
import { Costs } from "../../Costs";
import { useContext } from "react";
export default function SalesRollinPage() {
  const ChoserContainer = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const doctorId = Number(urlParams.get("id"));
  const [startRollinLink, setStartRollinLink] = useState(false);
  const [midTextType, setMidTextType] = useState(false);
  const stocks = useContext(Costs);
  useEffect(() => {
    if (localStorage.changeMidTypeText) {
      setMidTextType(true);
    }
  }, []);

  return (
    <AnimatedPage>
      <div className="sales_rollin_page_container" ref={ChoserContainer}>
        <BackToSearchButton />
        <div className={`sales_MidText_container`}>
          {stocks && stocks.length > 0 && (
            <img className="text_background" src={stocks[doctorId].imgCard} />
          )}
        </div>
        <p className="choose_date_time">Выберите дату и время приема</p>
        <TimeChoser
          choserContainerRef={ChoserContainer}
          setStartRollinLink={setStartRollinLink}
        />
        <p className="date_timer_awarener_text">
          Кнопка активируется после выбора даты и времени
        </p>
        <img className="date_timer_awarener_img" src={chooseDateTimeImg} />
        <Link
          to={startRollinLink ? "/fillYourName?key=actions" : ""}
          className={startRollinLink ? "" : "open_button"}
        >
          <EnrollBottomBlueButton />
        </Link>
      </div>
    </AnimatedPage>
  );
}
