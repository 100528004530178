import "./gigienistsButton.css"
import toothchairImg from "../../img/gigienists.png"

export default function GigienistsButton() {
    return (
      <div tabIndex="0" className="btnRight gigienists_button_container doctor_swiper_button small_mover_button_color_disabled">
        <img src={toothchairImg}/>
        <p>Гигиенисты</p>
      </div>
    );
  }