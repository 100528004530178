import "./rollinFinalPage.css";
import AnimatedPage from "../AnimatedPage";
import YouRolledFinal from "../../components/youRolledFinal/youRolledFinal";
import salesRollToothImg from "../../img/salesRollTooth.png";
import background from "./background.png";
import img0 from "./1.png";
import img1 from "./2.png";
import img2 from "./3.png";
import img3 from "./4.png";
import img4 from "./5.png";
import img5 from "./6.png";
import img6 from "./7.png";
import okImg from "../../img/galochkaFinal.png";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../Context";
const WindowBack = () => {
  localStorage.removeItem("chosenDay");
  localStorage.removeItem("chosenTime");
  localStorage.removeItem("chosenName");
  localStorage.removeItem("chosenTel");
};
export default function RollinFinalPage() {
  const doctors = useContext(Context);
  const urlParams = new URLSearchParams(window.location.search);
  const doctorId = Number(urlParams.get("id"));
  const type = urlParams.get("type");
  console.log(type);
  return (
    <AnimatedPage>
      {doctors && doctors.length > 0 && (
        <div
          className="rollin_final_page_container"
          style={
            localStorage.doctorRolledName
              ? {
                  backgroundImage: `url(${background})`,
                  backgroundSize: "100%",
                }
              : { background: "rgba(0, 194, 255, 1)" }
          }
        >
          {localStorage.doctorRolledName && type && type == '"doc"' ? (
            <img
              src={doctors[doctorId].photoCard}
              alt=""
              className="doctor_final_img"
            />
          ) : (
            <img
              src={salesRollToothImg}
              alt=""
              className="tooth_final_rollin"
            />
          )}

          <div className="bottom_info_final_rolling">
            <img className="bottom_info_final_rolling_ok" src={okImg} />
            {type && type == '"doc"' ? (
              <p className="bottom_info_final_rolling_title">
                Вы записаны на прием! <br />
                <b>Ваш врач:</b> <br />
                {doctors[doctorId].name}
              </p>
            ) : (
              <p className="bottom_info_final_rolling_title">
                Вы записаны на прием!
              </p>
            )}
            <p className="bottom_info_final_rolling_text">
              В самое ближайшее время с Вами свяжется наш администратор <br />{" "}
              для уточнения деталей.
            </p>
            <Link
              to="/"
              tabIndex="0"
              className="btnLeft bottom_info_final_button_container"
              onClick={WindowBack}
            >
              <p>Вернуться на главную</p>
            </Link>
          </div>
        </div>
      )}
    </AnimatedPage>
  );
}
