import "./orthodontistsSecButton.css"
import doubletoothImg from "../../img/doubletooth.png"

export default function OrthodontistsSecButton() {
    return (
      <div tabIndex="0" className="btnRight orthodontistsSec_button_container doctor_swiper_button small_mover_button_color_disabled">
        <img src={doubletoothImg}/>
        <p>Ортодонты</p>
      </div>
    );
  }