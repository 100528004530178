import "./moreInfoVinirs.css"
import moreInfoVinirsImg from "../../img/moreInfoVinirsImg.png"
import moreInfoVinirsImg2 from "../../img/moreInfoVinirsImg2.png"
import BackToSearchButton from "../../components/backToSearchButton/backToSearchButton"
import moreInfoVinirsTooth1 from "../../img/moreInfoVinirsTooth1.png"
import moreInfoVinirsTooth2 from "../../img/moreInfoVinirsTooth2.png"
import moreInfoVinirsTooth3 from "../../img/moreInfoVinirsTooth3.png"
import moreInfoVinirsTooth4 from "../../img/moreInfoVinirsTooth4.png"
import moreInfoVinirsTooth5 from "../../img/moreInfoVinirsTooth5.png"
import moreInfoVinirsTooth6 from "../../img/moreInfoVinirsTooth6.png"
import moreInfoVinirsTooth7 from "../../img/moreInfoVinirsTooth7.png"
import moreInfoVinirsTooth8 from "../../img/moreInfoVinirsTooth8.png"
import DoctorAboutCard from "../../components/doctorAboutCards/doctorAboutCard";
import { Link } from "react-router-dom";
import AnimatedPage from "../AnimatedPage"

export default function MoreInfoVinirs(){
    return(
        <AnimatedPage>
        <div className="more_info_vinirs_container">
            <BackToSearchButton/> 
            <img className="more_vinirs_head_img" src={moreInfoVinirsImg}/>
            <div className="more_vinirs_text_box">
                <p className="more_vinirs_text_title">Установка винир</p>
                <p className="more_vinirs_text_text">Мы предлагаем в процессе консультации и составления плана лечения выбрать цвет, который будет у зубов после установки виниров.</p>
                <div className="tooth_imgs_box">
                    <div className="tooths_row">
                        <img className="tooth_img" src={moreInfoVinirsTooth1}/>
                        <img className="tooth_img" src={moreInfoVinirsTooth2}/>
                        <img className="tooth_img" src={moreInfoVinirsTooth3}/>
                        <img className="tooth_img" src={moreInfoVinirsTooth4}/>
                    </div>
                    <div className="tooths_row">
                        <img className="tooth_img" src={moreInfoVinirsTooth5}/>
                        <img className="tooth_img" src={moreInfoVinirsTooth6}/>
                        <img className="tooth_img" src={moreInfoVinirsTooth7}/>
                        <img className="tooth_img" src={moreInfoVinirsTooth8}/>
                    </div>
                </div>
                <p className="more_vinirs_text_text">Зубы могут быть как очень белые - "голливудская улыбка", так и естественного оттенка.</p>
                <img className="more_vinirs_head_img" src={moreInfoVinirsImg2}/>
                <p className="more_vinirs_text_title">Компьютерный томограф HDX WILL Dentri 3D</p>
                <p className="more_vinirs_text_text">Работая на оборудовании от компании HDX WILL, врач может наблюдать необходимый участок в 3D-формате, обладая возможностью разбивать проекцию на слои, строить любые требуемые проекции и сечения, выделять и распечатывать срезы в разных плоскостях. Расширенное поле зрения расширяет возможности использования Dentri в терапии, ортодонтии, дентальной имплантологии, отоларингологии и хирургии.</p>
                <p className="more_vinirs_text_title">Направления</p>
                <p className="more_vinirs_text_text">1. Расширенная и комплексная диагностика с подготовкой плана лечения</p>
                <p className="more_vinirs_text_text">2. Эстетическое и функциональное восстановление с помощью вкладок, коронок и виниров, а также ультраниров</p>
                <p className="more_vinirs_text_text">3. Протезирование зубов мостовидными конструкциями на своих зубах или имплантах</p>
                <p className="more_vinirs_text_text">4. Перепротезирование. Работа со сложными кейсами и случаями, когда "результат не понравился" или не получился.</p>
                <p className="more_vinirs_text_text">5. Тотальные работы на обе челюсти и сложные случаи. Возвращение удобства и эстетики улыбки</p>
                <p className="more_vinirs_text_text">6. Тотальные работы на одну челюсть и план лечения по восстановлению второй челюсти.</p>
                <p className="more_vinirs_text_title">Виниры, если отстутствуют зубы</p>
                <p className="more_vinirs_text_text">В тех случаях, когда мы имеем дело с адентией, то есть полным отсутствием зубов, пациенту может быть предложено тотальное протезирование на имплантах.</p>
                <p className="more_vinirs_text_text">Ортопедическое лечение также проводится с использованием дентального микроскопа.Он помогает нашим врачам добиваться точного прилегания накладок, что особенно важно при работе с винирами и ультранирами.</p>
                <p className="more_vinirs_text_text">Как это происходит? Благодаря многократному увеличению мы минимально обрабатываем ткани ваших зубов, готовя их к фиксации керамических накладок. Такой подход позволяет создавать максимально естественные и эстетичные реставрации, которые радуют наших пациентов.</p>
                <p className="more_vinirs_text_title">Лечащие врачи</p>
                <Link to="/docinfo"><DoctorAboutCard/></Link>
                <Link to="/docinfo"><DoctorAboutCard/></Link>
            </div>
        </div>
        </AnimatedPage>
    )
}