import "./doctorListPage.css";
import BackToSearchButton from "../../components/backToSearchButton/backToSearchButton";
import DoctorAboutCard from "../../components/doctorAboutCards/doctorAboutCard";
import { Link } from "react-router-dom";
import AnimatedPage from "../AnimatedPage";
import { useEffect, useRef } from "react";
import Img1 from "./1.png";
import Img2 from "./2.png";
import Img3 from "./3.png";
import Img4 from "./4.png";
import Img5 from "./5.png";
import Img6 from "./6.png";
import Img7 from "./7.png";
import { Id } from "../../const";
import { useState } from "react";
import { useContext } from "react";
import { Context } from "../../Context";
export default function DoctorListPage() {
  const ListContainerRef = useRef(null);
  const scrollDownContainer = useRef(null);
  const doctorCard = document.getElementsByClassName("card_container");
  const scrollHaver = useRef(null);
  var endEnroll;
  var fromStarter = localStorage.getItem("starterEnroll");
  if (fromStarter == "true") {
    endEnroll = true;
  }
  const scrollFunction = () => {
    if (scrollDownContainer.current.style != "opacity: 0") {
      scrollDownContainer.current.style = "opacity: 0";
    }
  };
  const doctors = useContext(Context);
  useEffect(() => {
    localStorage.setItem("type", "Запись через врача");
    document.addEventListener("scroll", scrollFunction);
    setTimeout(() => {
      for (let i = 0; i < doctorCard.length; i++) {
        doctorCard[i].style = "transform: scale(1)";
      }
    }, 300);
    return () => document.removeEventListener("scroll", scrollFunction);
  }, []);
  return (
    <AnimatedPage>
      <div className="doctor_list_container" ref={ListContainerRef}>
        <BackToSearchButton />
        <div className="doctor_list" ref={scrollHaver}>
          {doctors &&
            doctors.length > 0 &&
            doctors.map((em, index) => {
              return (
                <Link
                  key={index}
                  onClick={() => {
                    localStorage.setItem("doctorId", "0");
                    localStorage.setItem("doctorName", em.name);
                    localStorage.setItem(
                      "doctorRolledName",
                      "Вы записаны на прием к Князевой Анастасии Николаевне !"
                    );
                  }}
                  to={
                    endEnroll == true ? "/fillYourName" : `/docinfo?id=${index}`
                  }
                  className="card_container"
                >
                  <DoctorAboutCard
                    img={em.photoList}
                    title={em.name}
                    subTitle={em.education}
                    summ={em.servicesNum}
                  />
                </Link>
              );
            })}
        </div>
        <div
          className="doctor_list_scroll_down_container"
          style={{ opacity: 1 }}
          ref={scrollDownContainer}
        >
          <div className="doctor_list_scroll_down">Листайте вниз</div>
        </div>
      </div>
    </AnimatedPage>
  );
}
