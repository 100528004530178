import "./hotServices.css"
import hotServicesImg1 from "../../img/hotServicesImg1.png"
import { Link } from "react-router-dom"
export default function HotServices(props){
    return(
        <div className="hot_services_container">
            <div className="hot_services_upper">
                <img src={props.img}/>
                <div className="hot_services_text">
                    <p className="hot_services_text_title">{props.title}</p>
                    <p className="hot_services_text_info">{props.info}</p>
                    <p className="hot_services_text_money">{props.money}</p>
                </div>
            </div>
            <div className="hot_services_downer">
                <Link to="/moreInfoVinirs"><button className="hot_services_more_info small_mover_button_color_disabled">Подробнее</button></Link>
                <Link to="/startRollin"><button className="hot_services_more_rollin big_mover_button_color_disabled">Записаться</button></Link>
            </div>
        </div>
    )
}