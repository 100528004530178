import "./examplesPage.css"
import YourQuestionsHeader from "../../components/yourQuestionsHeader/yourQuests"
import AnimatedPage from "../AnimatedPage"
import ExamplesMiniPartSlider from "../../components/examplesMiniPartSlider/examplesMiniPartSlider"
import examplesMini1 from "../../img/examplesMini1.png"
import examplesMini2 from "../../img/examplesMini2.png"
import examplesMini3 from "../../img/examplesMini3.png"
import ExamplesCard from "../../components/examplesCard/examplesCard"
import { useState, useRef, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom";
import examplesCard1Img from "../../img/examplesCard1.png"
import examplesCard2Img from "../../img/examplesCard2.png"
import FullNazad from "../../components/fullNazad/fullNazad"




export default function ExamplesPage(){
    // const [currentExampleMini, setcurrentExampleMini] = useState(1)
    // const doctorTypeSwiper = useRef(null);
    // useEffect(()=>{
    //     doctorTypeSwiper.current.addEventListener("click",scrollHandler)
    // },[])
    // const scrollHandler=()=>{
    //     let sectionWidth=doctorTypeSwiper.current.scrollWidth/3;
    //     let sectionIndex=Math.floor(doctorTypeSwiper.current.scrollLeft/sectionWidth)
    //     if(doctorTypeSwiper.current.scrollLeft===sectionWidth*sectionIndex){
    //         setcurrentExampleMini(sectionIndex+1)
    //         console.log(doctorTypeSwiper.current.scrollLeft,sectionWidth,Math.floor(doctorTypeSwiper.current.scrollLeft/sectionWidth),sectionIndex)

    //     }
    // }
    var requestArr={
        examplesMini: [
            {
                id: 0,
                title: "Виниры",
                img: examplesMini1,
            },
            {
                id: 1,
                title: "Брекеты",
                img: examplesMini2,
            },
            {
                id: 2,
                title: "Лечение кариеса",
                img: examplesMini3,
            }
        ],
        examplesCard:[
            {
                id: 0,
                img: examplesCard1Img,
                title: "Виниры Silko",
                name: "Анастасия, 25 лет",
                text: "Расширенная и комплексная диагностика с подготовкой плана лечения...",
            },
            {
                id: 1,
                img: examplesCard2Img,
                title: "Виниры Silko",
                name: "Валерия, 23 года",
                text: "Расширенная и комплексная диагностика с подготовкой плана лечения...",
            },
            {
                id: 2,
                img: examplesCard1Img,
                title: "Виниры Silko",
                name: "Тамара, 35 лет",
                text: "Расширенная и комплексная диагностика с подготовкой плана лечения...",
            },
        ]
    }
    const TrainRefExamples = useRef(null)
    const [currentExamplesCategories, setCurrentExamplesCategories] = useState(0);
    
    const scrollRight=()=>{
        let sectionWidth=TrainRefExamples.current.scrollWidth;
        TrainRefExamples.current.style=`transform: translateX(-${sectionWidth/2}px)`
        setCurrentExamplesCategories(1)
        console.log()
    }
    const scrollLeft=()=>{
        setCurrentExamplesCategories(0)
        TrainRefExamples.current.style=`transform: translateX(0px)`
    }
    return(
        <AnimatedPage>
        <div className="examples_page_container">
            <YourQuestionsHeader/> 
            <p className="works_tipy_title">Виды работ</p>
            <div className="train_box">
                <div className="crazy_train_examples" ref={TrainRefExamples}>
                    <div className="train_station">
                        {requestArr.examplesMini.map(requestArrs =>(
                            <ExamplesMiniPartSlider key={requestArrs.id} title={requestArrs.title} img={requestArrs.img}/>
                        ))}
                    </div>
                    <div className="train_station">
                        {requestArr.examplesMini.map(requestArrs =>(
                            <ExamplesMiniPartSlider key={requestArrs.id} title={requestArrs.title} img={requestArrs.img}/>
                        ))}
                    </div>
                </div>
            </div>
            <div className="train_arrows">
                <div onClick={scrollLeft} className={currentExamplesCategories === 1 ? "choose_examples_arrows_icons_active choose_examples_arrows_icons not_close":"choose_examples_arrows_icons_disabled choose_examples_arrows_icons not_close"}>&#8249;</div>
                <div onClick={scrollRight} className={currentExamplesCategories === 0 ? "choose_examples_arrows_icons_active choose_examples_arrows_icons not_close":"choose_examples_arrows_icons_disabled choose_examples_arrows_icons not_close"}>&#8250;</div>
            </div>
            <div className="examples_cards_padding">
                {requestArr.examplesCard.map(requestArrs =>(
                        <ExamplesCard key={requestArrs.id} img={requestArrs.img} title={requestArrs.title} name={requestArrs.name} text={requestArrs.text}/>
                    ))}
            </div>
            
        </div>
        </AnimatedPage>
    )
}