import SalesButton from "../../components/salesButton/salesButton.jsx";
import starterBallImg from "../../img/starterball.png";
import EnrollButton from "../../components/enrollButton/enrollButton.jsx";
import ExamplesButton from "../../components/examplesButton/examplesButton.jsx";
import AboutDoctorsButton from "../../components/aboutDoctorsButton/aboutDoctorsButton.jsx";
import ServicesButton from "../../components/servicesButton/servicesButton.jsx";
import doctorstuffImg from "../../img/doctorstuff.png";
import toothinhandImg from "../../img/toothinhand.png";
import womanmanImg from "../../img/womanman.png";
import salesImg from "../../img/30sale.png";
import womanImg from "../../img/woman.png";
import { Link, useNavigate } from "react-router-dom";
import "./starterPage.css";
import AnimatedPage from "../AnimatedPage";
import logoText from "../../img/logoText.svg";
import { useEffect, useState } from "react";
import aboutCompanyIcon from "../../img/aboutCompanyIcon.png";
import feedbackIcon from "../../icons/message.svg";
import { DelayAnimation } from "../../const.js";
import skakovskyApple from "../../img/skakovskyApple.png";
import skakovskyYandex from "../../img/skakovskyYandex.png";
import skakovsky2Gis from "../../img/skakovsky2Gis.png";
import skakovskyZoon from "../../img/skakovskyZoon.png";
import skakovskyYell from "../../img/skakovskyYell.png";
export default function StarterPage() {
  const [startAnimation, setStartAnimation] = useState(false);
  var alreadyBeenIndex = 3700;
  if (sessionStorage != "") {
    if (sessionStorage.getItem("alreadyBeen") == "yes") {
      alreadyBeenIndex = 0;
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        setStartAnimation(true);
      }, 300);
      setTimeout(() => {
        window.document.getElementsByClassName("starter_welcome")[0].style =
          "opacity: 0";
        setTimeout(() => {
          window.document.getElementsByClassName(
            "starter_support"
          )[0].style.opacity = "1";
          window.document.getElementsByClassName(
            "starter_support_feedback_left"
          )[0].style.transform = "scale(1)";
          window.document.getElementsByClassName(
            "starter_support_feedback"
          )[0].style.transform = "scale(1)";
        }, 1000);
        setTimeout(() => {
          for (
            let i = 0;
            i < window.document.getElementsByClassName("ranking_part").length;
            i++
          ) {
            window.document.getElementsByClassName("ranking_part")[
              i
            ].style.transform = "scale(1)";
          }
        }, 1500);
      }, 1300);
    }, alreadyBeenIndex);
  }, []);
  const Navigate = useNavigate();
  const navigateNext = (path) => {
    Navigate(path);
    localStorage.setItem("HeaderBackLink", "/");
  };
  return (
    <AnimatedPage>
      <div className="starter_page_container">
        <div className="starter_up">
          <div className="starter_welcome">Добро пожаловать!</div>
          <div className="starter_support">
            <div
              className="starter_support_feedback_left"
              onClick={() => Navigate("/clinicInfo")}
            >
              <img src={aboutCompanyIcon} />О клинике
            </div>
            <div className="skakovsky_title_box">
              <img className="skakovskyApple" src={skakovskyApple} />
              <img src={logoText} alt="" style={{ width: "148px" }} />
            </div>
            <a
              href="https://wa.me/79266942491"
              className="starter_support_feedback"
            >
              <img src={feedbackIcon} />
              <p>Написать</p>
            </a>
          </div>
        </div>
        <div className="starter_middle">
          <div className="ranking_part">
            <img className="ranking_img" src={skakovskyYandex} />
            <p className="ranking_points">4.3/5</p>
            <p className="ranking_reviews">
              <span>31</span> отзыв
            </p>
          </div>
          <div className="ranking_part">
            <img className="ranking_img" src={skakovsky2Gis} />
            <p className="ranking_points">5/5</p>
            <p className="ranking_reviews">
              <span>12</span> отзывов
            </p>
          </div>
          <div className="ranking_part">
            <img className="ranking_img" src={skakovskyZoon} />
            <p className="ranking_points">4.1/5</p>
            <p className="ranking_reviews">
              <span>14</span> отзывов
            </p>
          </div>
          <div className="ranking_part">
            <img className="ranking_img" src={skakovskyYell} />
            <p className="ranking_points">4.1/5</p>
            <p className="ranking_reviews">
              <span>25</span> отзывов
            </p>
          </div>
        </div>
        <div className="starter_down">
          <div className="left_column">
            <div
              tabIndex="0"
              onClick={() => {
                navigateNext("/servicesSurgeonsPage");
              }}
              style={
                startAnimation
                  ? { transform: "scale(1)" }
                  : { transform: "scale(0)" }
              }
              className="btnLeft starter_button big_mover_button_color_disabled"
            >
              <img className="starter_img3" src={doctorstuffImg} />
              <p className="starter_button_p">Услуги</p>
            </div>
            <div
              tabIndex="0"
              onClick={() => {
                navigateNext("/sales");
              }}
              style={
                startAnimation
                  ? { transform: "scale(1)" }
                  : { transform: "scale(0)" }
              }
              className="btnLeft starter_button big_mover_button_color_disabled"
            >
              <img className="starter_img" src={salesImg} />
              <p className="starter_button_p">Акции и скидки</p>
            </div>
          </div>
          <div className="right_column">
            <div
              tabIndex="0"
              onClick={() => {
                navigateNext("/startRollin");
              }}
              style={
                startAnimation
                  ? { transform: "scale(1)" }
                  : { transform: "scale(0)" }
              }
              className="btnLeft starter_button big_mover_button_color_disabled"
            >
              <img className="starter_img2" src={womanImg} />
              <p className="starter_button_p">Записаться</p>
            </div>
            <div
              tabIndex="0"
              onClick={() => {
                navigateNext("/docslist");
              }}
              style={
                startAnimation
                  ? { transform: "scale(1)" }
                  : { transform: "scale(0)" }
              }
              className="btnLeft starter_button big_mover_button_color_disabled"
            >
              <img className="starter_img" src={womanmanImg} />
              <p className="starter_button_p">О врачах</p>
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}

// "/alldocs"
