import "./orthopedistsButton.css"
import toothscanImg from "../../img/toothscan.png"

export default function OrthopedistsButton() {
    return (
      <div tabIndex="0" className="btnRight orthopedists_button_container doctor_swiper_button small_mover_button_color_disabled">
        <img src={toothscanImg}/>
        <p>Ортопеды</p>
      </div>
    );
  }