import "./salesPage.css";
import SalesRightText from "../../components/salesRightText/salesRightText";
import SalesLeftText from "../../components/salesLeftText/salesLeftText";
import YourQuestionsHeader from "../../components/yourQuestionsHeader/yourQuests";
import AnimatedPage from "../AnimatedPage";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";

import { Costs } from "../../Costs";
import { useContext } from "react";
export default function SalesPage() {
  var requestArr = {
    SideText: [
      {
        id: 0,
        title: "Установка винир",
        saleAbout: " на все виды установки винир",
        saleAmount: "20%",
        salePeriod: "с 27 сентября по 5 октября",
        side: "Left",
      },
      {
        id: 1,
        title: "Лечение дёсен",
        saleAbout: " на все виды дёсен",
        saleAmount: "40%",
        salePeriod: "с 17 сентября по 15 октября",
        side: "Right",
      },
      {
        id: 2,
        title: "Лечение дёсен",
        saleAbout: " на все виды дёсен",
        saleAmount: "40%",
        salePeriod: "с 17 сентября по 15 октября",
        side: "Left",
      },
      {
        id: 3,
        title: "Лечение дёсен",
        saleAbout: " на все виды дёсен",
        saleAmount: "40%",
        salePeriod: "с 17 сентября по 15 октября",
        side: "Right",
      },
    ],
  };
  const imgArr = useContext(Costs);
  const salesLeftRef = document.getElementsByClassName(
    "sales_LeftText_container"
  );
  const salesRightRef = document.getElementsByClassName(
    "sales_RightText_container"
  );
  useEffect(() => {
    localStorage.setItem("type", "Акции и Скидки");
    setTimeout(() => {
      for (let i = 0; i < salesLeftRef.length; i++) {
        // salesLeftRef[i].style =
        //   "transform: translateX(0vw);background: url('./img/1a.png')";
        salesRightRef[i].style = "transform: translateX(0vw)";
        console.log(salesLeftRef[i].style);
      }
    }, 300);
  }, []);

  return (
    <AnimatedPage>
      <div className="sales_page_container">
        <YourQuestionsHeader />
        <div className="our_actions">Наши акции</div>
        {imgArr &&
          imgArr.length > 0 &&
          imgArr.map((img, index) => (
            <div key={index} className="actions">
              <img src={img.imgList} alt="" />
              <Link to={"/salesRollin?id=" + index} className="actions_button">
                <button
                  tabIndex="0"
                  className="btnLeft sales_rollin_button big_mover_button_color_disabled"
                >
                  Записаться
                </button>
              </Link>
            </div>
          ))}
      </div>
    </AnimatedPage>
  );
}
