import "./pediatricianButton.css"
import toothImg from "../../img/tooth.png"

export default function PediatricianButton() {
    return (
      <div tabIndex="0" className="btnRight pediatrician_button_container doctor_swiper_button small_mover_button_color_disabled">
        <img src={toothImg}/>
        <p>Детский врач</p>
      </div>
    );
  }