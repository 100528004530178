import "./rollinDoctorPage.css";
import BackToSearchButton from "../../components/backToSearchButton/backToSearchButton";
import AnimatedPage from "../AnimatedPage";
import ToothDoctorImg from "../../img/toothdoctor.png";
import chooseDateTimeImg from "../../img/chooseDateTime.png";
import { Link } from "react-router-dom";
import TimeChoser from "../../components/timeChoser/timeChoser";
import { useEffect, useRef, useState } from "react";
import EnrollBottomBlueButton from "../../components/enrollBottomBlueButton/enrollBottomBlueButton";
import { Id, doctors } from "../../const";
import img0 from "./1.png";
import img1 from "./2.png";
import img2 from "./3.png";
import img3 from "./4.png";
import img4 from "./5.png";
import img5 from "./6.png";
import img6 from "./7.png";
import { Context } from "../../Context";
import { useContext } from "react";
const arrImg = [img0, img1, img2, img3, img4, img5, img6];
export default function RollinDoctorPage() {
  const ChoserContainer = useRef(null);
  const [startRollinLink, setStartRollinLink] = useState(false);
  const doctors = useContext(Context);
  const urlParams = new URLSearchParams(window.location.search);
  const doctorId = Number(urlParams.get("id"));
  console.log(doctorId);
  return (
    <AnimatedPage>
      {doctors && doctors.length > 0 && (
        <div className="rollin_doctor_page_container" ref={ChoserContainer}>
          <BackToSearchButton />
          <img
            className="rollin_doctor_page_photo"
            src={doctors[doctorId].photoList}
          />
          <p className="rollin_doctor_page_name">{doctors[doctorId].name}</p>
          <p className="rollin_doctor_page_jobname">
            {doctors[doctorId].education}
          </p>
          <p className="rollin_doctor_page_choose_date_time">
            Выберите дату и время приема
          </p>
          <TimeChoser
            choserContainerRef={ChoserContainer}
            setStartRollinLink={setStartRollinLink}
          />
          <p className="rolling_doctor_page_awarener_text">
            Кнопка активируется после выбора даты и времени
          </p>
          <img
            className="rollin_doctor_page_awarener_img"
            src={chooseDateTimeImg}
          />
          <Link
            to={startRollinLink ? "/fillYourName?id=" + doctorId : ""}
            className={startRollinLink ? "" : "open_button"}
          >
            <EnrollBottomBlueButton />
          </Link>
        </div>
      )}
    </AnimatedPage>
  );
}
