import "./leadersButton.css"
import toothrepairerImg from "../../img/toothrepairer.png"

export default function LeadersButton() {
    return (
      <div tabIndex="0" className="btnRight leaders_button_container doctor_swiper_button small_mover_button_color_disabled">
        <img src={toothrepairerImg}/>
        <p>Руководители</p>
      </div>
    );
  }