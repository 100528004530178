import "./ClinicMain.css";
import backToSearchArrowImg from "../../img/backToSearchArrow.png";
import { Link } from "react-router-dom";
import EnrollBottomBlueButton from "../../components/enrollBottomBlueButton/enrollBottomBlueButton";
import { useEffect } from "react";
import { domain } from "../../const";
import { useState } from "react";
export const ClinicMain = () => {
  const WindowBack = () => {
    window.history.back();
  };
  const [info, setinfo] = useState("");
  useEffect(() => {
    let formData = new FormData();
    formData.append("ID", localStorage.getItem("keySt"));
    fetch(domain + "/getInfo.php", {
      body: formData,
      method: "POST",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setinfo(data);
      });
  }, []);
  return (
    <div className="clinic__body">
      <div
        tabIndex="0"
        className="btnLeft back_to_starter"
        onClick={WindowBack}
      >
        <img src={backToSearchArrowImg} />
        На главную
      </div>
      <h1>О клинике</h1>
      <p dangerouslySetInnerHTML={{ __html: info }}></p>
      <Link to="/startRollin">
        <EnrollBottomBlueButton />
      </Link>
    </div>
  );
};
