import "./youRolledFinal.css";
import okImg from "../../img/galochkaFinal.png";
import { Link } from "react-router-dom";
import BackToStartButton from "../../components/backToStartButton/backToStartButton";
import { useEffect } from "react";
import { doctors } from "../../const";

export default function YouRolledFinal() {
  const WindowBack = () => {
    localStorage.removeItem("chosenDay");
    localStorage.removeItem("chosenTime");
    localStorage.removeItem("chosenName");
    localStorage.removeItem("chosenTel");
  };
  return (
    <div className="bottom_info_final_rolling">
      <img className="bottom_info_final_rolling_ok" src={okImg} />
      <p className="bottom_info_final_rolling_title">
        {localStorage.doctorRolledName
          ? localStorage.doctorRolledName
          : "Вы записаны на прием!"}
      </p>
      <p className="bottom_info_final_rolling_text">
        В самое ближайшее время с Вами свяжется наш администратор <br /> для
        уточнения деталей.
      </p>
      <Link
        to="/"
        tabIndex="0"
        className="btnLeft bottom_info_final_button_container"
        onClick={WindowBack}
      >
        <p>Вернуться на главную</p>
      </Link>
    </div>
  );
}
