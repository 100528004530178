import "./examplesAboutInfoPage.css"
import backToSearchArrowImg from "../../img/backToSearchArrow.png";
import AnimatedPage from "../AnimatedPage";
import ExamplesAboutInfoHead from "../../img/examplesAboutInfoHead.png";
import examplesResultsImg from "../../img/examplesResultsImg.png"
import DoctorAboutCard from "../../components/doctorAboutCards/doctorAboutCard";

import { Link } from "react-router-dom";


export default function ExamplesAboutInfoPage(){
    const WindowBack=()=>{
        window.history.back()
    }
    return(
        <AnimatedPage>
        <div className="examples_about_info_page_container">
            <div tabIndex="0" className="btnLeft back_to_examples" onClick={WindowBack}>
                <img src={backToSearchArrowImg}/>
                <p>Вернуться к примерам</p>
            </div>
            <div className="examples_about_info_full_box">
                <img className="examples_about_info_head_img" src={ExamplesAboutInfoHead}/>
                <p className="examples_about_info_full_text_title">Установка винир Silko</p>
                <p className="examples_about_info_full_text_text">В нашу клинику пришла Анастасия без выравненных зубов с помощью брекет систем. Выбор девушки пал на оттенок виниров “18”.</p>
                <p className="examples_about_info_full_text_text">Вместе с Анастасией мы примерили цвет и определились с методом установки винир.</p>
                <p className="examples_about_info_full_text_text">Работая на оборудовании от компании HDX WILL, врач может наблюдать необходимый участок в 3D-формате.</p>
                <p className="examples_about_info_full_text_blue">Результат установки вы можете увидеть ниже.</p>
                <img className="examples_results_img" src={examplesResultsImg}/>
                <p className="examples_about_info_full_text_blue">Компьютерный томограф HDX WILL Dentri 3D</p>
                <p className="examples_about_info_full_text_text">Работая на оборудовании от компании HDX WILL, врач может наблюдать необходимый участок в 3D-формате, обладая возможностью разбивать проекцию на слои, строить любые требуемые проекции и сечения, выделять и распечатывать срезы в разных плоскостях. Расширенное поле зрения расширяет возможности использования Dentri в терапии, ортодонтии, дентальной имплантологии, отоларингологии и хирургии.</p>

                <p className="examples_about_info_full_text_blue">Направления</p>
                <p className="examples_about_info_full_text_text">1. Расширенная и комплексная диагностика с подготовкой плана лечения</p>
                <p className="examples_about_info_full_text_text">2. Эстетическое и функциональное восстановление с помощью вкладок, коронок и виниров, а также ультраниров</p>
                <p className="examples_about_info_full_text_text">3. Протезирование зубов мостовидными конструкциями на своих зубах или имплантах</p>
                <p className="examples_about_info_full_text_text">4. Перепротезирование. Работа со сложными кейсами и случаями, когда "результат не понравился" или не получился.</p>
                <p className="examples_about_info_full_text_text">5. Тотальные работы на обе челюсти и сложные случаи. Возвращение удобства и эстетики улыбки</p>
                <p className="examples_about_info_full_text_text">6. Тотальные работы на одну челюсть и план лечения по восстановлению второй челюсти.</p>
            
                <p className="examples_about_info_full_text_blue">Виниры, если отстутствуют зубы</p>
                <p className="examples_about_info_full_text_text">В тех случаях, когда мы имеем дело с адентией, то есть полным отсутствием зубов, пациенту может быть предложено тотальное протезирование на имплантах.</p>
                <p className="examples_about_info_full_text_text">Ортопедическое лечение также проводится с использованием дентального микроскопа.Он помогает нашим врачам добиваться точного прилегания накладок, что особенно важно при работе с винирами и ультранирами.</p>
                <p className="examples_about_info_full_text_text">Как это происходит? Благодаря многократному увеличению мы минимально обрабатываем ткани ваших зубов, готовя их к фиксации керамических накладок. Такой подход позволяет создавать максимально естественные и эстетичные реставрации, которые радуют наших пациентов.</p>
            
                <p className="examples_about_info_full_text_blue">Лечащие врачи</p>
            
                <Link to="/docinfo"><DoctorAboutCard/></Link>
                <Link to="/docinfo"><DoctorAboutCard/></Link>
            </div>
            
        </div>
        </AnimatedPage>
    )
}