import "./rollinDoctorFinalPage.css";
import AnimatedPage from "../AnimatedPage";
import rollinDoctorFinalPagePhoto from "../../img/rollinDoctorFinalPagePhoto.png";
import YouRolledFinal from "../../components/youRolledFinal/youRolledFinal";
import img0 from "./1.png";
import img1 from "./2.png";
import img2 from "./3.png";
import img3 from "./4.png";
import img4 from "./5.png";
import img5 from "./6.png";
import img6 from "./7.png";
const arrImg = [img0, img1, img2, img3, img4, img5, img6];
export default function RollinDoctorFinalPage() {
  return (
    <AnimatedPage>
      <div className="rollin_doctor_final_page_container">
        <img src={arrImg[localStorage.doctorId]} />
        <YouRolledFinal />
      </div>
    </AnimatedPage>
  );
}
