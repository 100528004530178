import "./surgeonsButton.css"
import toothlampImg from "../../img/toothlamp.png"

export default function SurgeonsButton() {
    return (
      <div tabIndex="0" className="btnRight surgeons_button_container doctor_swiper_button small_mover_button_color_disabled">
        <img src={toothlampImg}/>
        <p>Хирурги</p>
      </div>
    );
  }