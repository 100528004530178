import "./App.css";
import BottomPersonalMenu from "./bottomMenu/index";
import { Id } from "./const";
import StarterPage from "./pages/starter/starterPage.jsx";
import AlldoctorsPage from "./pages/alldoctors/alldoctorsPage.jsx";
import DoctorListPage from "./pages/doctorlist/doctorListPage";
import DoctorInfoPage from "./pages/doctorinfo/doctorInfoPage";
import SalesRollinPage from "./pages/salesRollinPage/salesRollinPage";
import FillYourNamePage from "./pages/fillYourNamePage/fillYourNamePage";
import FillYourNameServicesPage from "./pages/fillYourNamePage/fillYorNamePageServices";
import RollinFinalPage from "./pages/rollinFinalPage/rollinFinalPage";
import RollinDoctorPage from "./pages/rollinDoctorPage/rollinDoctorPage";
import RollinDoctorFinalPage from "./pages/rollinDoctorFinalPage/rollinDoctorFinalPage";
import ExamplesPage from "./pages/examplesPage/examplesPage";
import ExamplesAboutInfoPage from "./pages/examplesAboutInfoPage/examplesAboutInfoPage";
import StartRollinNew from "./pages/startRollinPage/startRollinNew";

import ServicesPage from "./pages/servicesPage/servicesPage";
import ServicesDoctorsPage from "./pages/servicesDoctorsPage/servicesDoctorsPage";
import ServicesSurgeonsPage from "./pages/servicesSurgeonsPage/servicesSurgeonsPage";
import MoreInfoVinirs from "./pages/moreInfoVinirs/moreInfoVinirs";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import SalesPage from "./pages/salesPage/salesPage";
import preloaderLogo from "./icons/preloader_logo.svg";
import FullNazad from "./components/fullNazad/fullNazad";
import { ClinicMain } from "./pages/aboutClinic/ClinicMain";
import { Context } from "./Context.js";
import { Costs } from "./Costs";

function App() {
  const location = useLocation();
  const ContainerRef = useRef(null);
  const preloaderLogoRef = useRef(null);
  var left = document.getElementsByClassName("left");
  var right = document.getElementsByClassName("right");
  var back = document.getElementsByClassName("back");
  var front = document.getElementsByClassName("front");
  var bottom = document.getElementsByClassName("bottom");
  var top = document.getElementsByClassName("top");
  const [doctors, setDoctors] = useState([]);
  const [stocks, setStocks] = useState([]);

  useEffect(() => {
    let formData = new FormData();
    formData.append("ID", Id);
    fetch("https://comeback-team.ru/getDoctors.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDoctors(data);
      });
    fetch("https://comeback-team.ru/getStocks.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStocks(data);
      });
  }, []);
  useEffect(() => {
    if (sessionStorage != "") {
      if (sessionStorage.getItem("alreadyBeen") == "yes") {
        ContainerRef.current.style.display = "none";
      } else {
        console.log(ContainerRef.current);
        setTimeout(() => {
          preloaderLogoRef.current.style.opacity = "1";
        }, 2700);
        setTimeout(() => {
          for (let i = 0; i < left.length; i++) {
            left[i].style.display = "none";
            right[i].style.display = "none";
            back[i].style.display = "none";
            front[i].style.display = "none";
            bottom[i].style.display = "none";
          }
          ContainerRef.current.style.transform = "scale(3)";
          setTimeout(() => {
            ContainerRef.current.style.opacity = 0;
            sessionStorage.setItem("alreadyBeen", "yes");
          }, 300);
        }, 3700);
      }
    }
  }, []);
  const getUpdates = () => {
    fetch(
      "https://api.telegram.org/bot5404163856:AAFwUhRkN08Ppwzz2lht6OuFJQ9fjX7Ca2E/getupdates"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(JSON.stringify(data));
      });
  };
  useEffect(() => {
    let tg = window.Telegram.WebApp;

    if (tg && tg.initDataUnsafe.user) {
      let formData = new FormData();
      let date = new Date();

      formData.append("id", tg.initDataUnsafe.user.id);
      formData.append("idSt", Id);
      formData.append("usernameTg", tg.initDataUnsafe.user.username);
      formData.append(
        "username",
        tg.initDataUnsafe.user.first_name +
          " " +
          tg.initDataUnsafe.user.last_name
      );
      formData.append("date", date.toString());
      setTimeout(() => {
        fetch("https://comeback-team.ru/authMain.php", {
          method: "POST",
          body: formData,
        });
      }, 2000);
    }
  });
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [location.pathname]);

  return (
    <div className="App">
      <div className="container" ref={ContainerRef}>
        <img
          className="preloader_logo"
          src={preloaderLogo}
          ref={preloaderLogoRef}
        />
        <div className="flex">
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
        </div>
        <div className="flex">
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
        </div>
        <div className="flex">
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
        </div>
        <div className="flex">
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
          <div className="cube">
            <div className="wall front"></div>
            <div className="wall back"></div>
            <div className="wall left"></div>
            <div className="wall right"></div>
            <div className="wall top"></div>
            <div className="wall bottom"></div>
          </div>
        </div>
      </div>

      <AnimatePresence wait>
        <Context.Provider value={doctors}>
          <Costs.Provider value={stocks}>
            <Routes>
              <Route path="/" element={<StarterPage />} />
              <Route path="/alldocs" element={<AlldoctorsPage />} />
              <Route path="/docslist" element={<DoctorListPage />} />
              <Route path="/docinfo" element={<DoctorInfoPage />} />
              <Route path="/sales" element={<SalesPage />} />
              <Route path="/salesRollin" element={<SalesRollinPage />} />
              <Route path="/fillYourName" element={<FillYourNamePage />} />
              <Route
                path="/fillYourNameServices"
                element={<FillYourNameServicesPage />}
              />
              <Route path="/rollinFinal" element={<RollinFinalPage />} />
              <Route path="/rollinDoctorPage" element={<RollinDoctorPage />} />
              <Route
                path="/rollinDoctorFinalPage"
                element={<RollinDoctorFinalPage />}
              />
              <Route path="/examplesPage" element={<ExamplesPage />} />
              <Route
                path="/examplesAboutInfo"
                element={<ExamplesAboutInfoPage />}
              />
              <Route path="/startRollin" element={<StartRollinNew />} />
              <Route path="/servicesPage" element={<ServicesPage />} />
              <Route
                path="/servicesDoctorsPage"
                element={<ServicesDoctorsPage />}
              />
              <Route
                path="/servicesSurgeonsPage"
                element={<ServicesSurgeonsPage />}
              />
              <Route path="/moreInfoVinirs" element={<MoreInfoVinirs />} />
              <Route path="/clinicInfo" element={<ClinicMain />} />
            </Routes>
          </Costs.Provider>
        </Context.Provider>
      </AnimatePresence>
    </div>
  );
}

export default App;
