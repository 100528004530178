import "./servicesPage.css";
import YourQuestionsHeader from "../../components/yourQuestionsHeader/yourQuests";
import servicesByType from "../../img/servicesByType.png";
import servicesByWay from "../../img/servicesByWay.png";
import HotServices from "../../components/hotServices/hotServices";
import hotServicesImg1 from "../../img/hotServicesImg1.png";
import hotServicesImg2 from "../../img/hotServicesImg2.png";
import hotServicesImg3 from "../../img/hotServicesImg3.png";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AnimatedPage from "../AnimatedPage";
import FullNazad from "../../components/fullNazad/fullNazad";
export default function ServicesPage() {
  var requestArr = {
    HotServices: [
      {
        id: 0,
        img: hotServicesImg1,
        title: "Установка винир",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et....",
        money: "от 6000₽/зуб",
      },
      {
        id: 1,
        img: hotServicesImg2,
        title: "Установка пломбы",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et....",
        money: "от 6000₽/зуб",
      },
      {
        id: 2,
        img: hotServicesImg3,
        title: "Лечение кариеса",
        info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et....",
        money: "от 6000₽/зуб",
      },
    ],
  };
  const scrollDownContainer = useRef(null);
  useEffect(() => {
    document.addEventListener("scroll", () => {
      scrollDownContainer.current.style = "opacity: 0";
    });
  }, []);
  const rollinTypeFirst = useRef(null);
  const rollinTypeSecond = useRef(null);
  const setActiveFirst = () => {
    rollinTypeFirst.current.className = "services_type_active services_type";
    rollinTypeSecond.current.className = "services_type_disabled services_type";
  };
  const setActiveSecond = () => {
    rollinTypeSecond.current.className = "services_type_active services_type";
    rollinTypeFirst.current.className = "services_type_disabled services_type";
  };
  return (
    <AnimatedPage>
      <div className="services_page_container">
        <YourQuestionsHeader />
        <p className="services_title">Выберите тип поиска</p>
        <div className="services_type_box">
          <Link
            to="/servicesDoctorsPage"
            className="services_type"
            ref={rollinTypeFirst}
            onClick={setActiveFirst}
          >
            <img className="services_type_img1" src={servicesByWay} />
            <p>По направлению лечения</p>
          </Link>
          <Link
            to="/servicesSurgeonsPage"
            className="services_type"
            ref={rollinTypeSecond}
            onClick={setActiveSecond}
          >
            <img className="services_type_img2" src={servicesByType} />
            <p>По требуемой услуге</p>
          </Link>
        </div>
        <p className="hot_services_title">Горящие предложения</p>
        {requestArr.HotServices.map((requestArrs) => (
          <HotServices
            key={requestArrs.id}
            title={requestArrs.title}
            info={requestArrs.info}
            money={requestArrs.money}
            img={requestArrs.img}
          />
        ))}
        <div
          className="doctor_list_scroll_down_container"
          ref={scrollDownContainer}
        >
          <div className="doctor_list_scroll_down">Листайте вниз</div>
        </div>
      </div>
    </AnimatedPage>
  );
}
