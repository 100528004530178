import "./examplesMiniPartSlider.css"
import examplesMini1 from "../../img/examplesMini1.png"
import { Link } from "react-router-dom";
export default function ExamplesMiniPartSlider(props){
    return(
        <Link to="/examplesAboutInfo">
        <div className="examples_mini_part_slider_container">
            <img src={props.img} className="examples_mini_part_img"/>
            <p className="examples_mini_part_title">{props.title}</p>
        </div>
        </Link>
    )
}