import "./terapephtsButton.css"
import toothrepairerImg from "../../img/terapephts.png"

export default function TerapephtsButton() {
    return (
      <div tabIndex="0" className="btnRight terapephts_button_container doctor_swiper_button small_mover_button_color_disabled">
        <img src={toothrepairerImg}/>
        <p>Терапевты</p>
      </div>
    );
  }