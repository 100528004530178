import {motion} from "framer-motion"

const animations ={
    initial: {opacity: 0, x:100, width:"100%"},
    animate: {opacity: 1, x:0, width:"100%"},
    exit: {opacity: 0, x:-200, width:"100%"},
}

export default function AnimatedPage({children}){
    return(
        <motion.div style={{width:"100vw", height:"100vh"}} variants={animations} initial="initial" animate="animate" exit="exit" transition={{duration: 0.15}}>
            {children}
        </motion.div>
    )
}